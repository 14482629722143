import axios from "../axios";


export function getRechargeProduct() {
    const url = "/memberCharge/index";
    return axios.request({
        url,
        method: 'get'
    })
}

export function getProductSku(data) {
    const url = `/memberCharge/subCategory${data ? `/${data}` : ''}`;
    return axios.request({
        url,
        method: 'get'
    })
}

export function createRechargeOrder(data) {
    const url = '/memberCharge/order/create';
    return axios.request({
        url,
        data,
    })
}


export function getOrderDetail(data) {
    const url = `/memberCharge/order/detail${data ? `/${data}` : ''}`;
    return axios.request({
        url,
    })
}

export function getOrderList(data) {
    const url = '/memberCharge/order/list'
    return axios.request({
        url,
        data
    })
}

export function saveSmsCode(data) {
    const url = '/memberCharge/order/setSmsCode';
    return axios.request({
        url,
        data
    })
}

//获取余额充值配置信息
export function getBalanceChargeCfg() {
    const url = '/member/account/charge/cfg/list';
    return axios.request({
        url,
        data: {
            pageSize: 100
        },  
        method: 'get'
    })
}

//确认充值
export function rechargeConfirm(data) {
    const url = `/member/account/select/charge/cfg`;
    return axios.request({
        url,
        data: {id: data},
        method: 'get'
    })
}

//充值记录
export function getRechargeList() {
    const url = '/member/account/trs/flow/charge/list'
    return axios.request({
        url,
        method: 'get'
    })
}

//消费记录
export function getTransactionList(data) {
    const url = '/member/account/trs/flow/list';
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function getMemberCardList(data) {
    const url = '/member/card/list';
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function bindCard(data) {
    const url = '/member/card/bind';
    return axios.request({
        url,
        data
    })
}

export function setCard(data) {
    const url = '/member/card/set';
    return axios.request({
        url,
        method: 'get',
        data
    })
}




