<template>
    <div class="root-wrap">
        <div v-if="loading" class="fx-fill fx-center fx-middle">
            <van-loading type="spinner" color="#FF4500">
            </van-loading>
        </div>
        <div v-else class="body-wrap">
            <!-- <div class="swipe" :style="bgStyle">
                <van-swipe autoplay="1000">
                    <van-swipe-item>
                        <img src="https://rrd.huawenwin.com/addons/hc_card/template/mobile/img/newpic/tequanbanner.png" style="width:100%;height: 80px;"/>
                    </van-swipe-item>
                    <van-swipe-item>
                        <img src="https://rrd.huawenwin.com/addons/hc_card/template/mobile/img/newpic/tequanbanner.png" style="width:100%;height: 80px;"/>
                    </van-swipe-item>
                </van-swipe>
            </div> -->
            <side-bar :list="sideList" :content-list="contentList" :loading="loading"></side-bar>
        </div>
        <div class="footer-wrap">
            <TabBar />
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash'
import TabBar from '@/components/tab-bar'
import SideBar from '@/components/side-bar'
import { getRechargeProduct } from '@api/recharge-request'
export default {
    data() {
        return {
            loading: false,
            sideList: [
            ],
            contentList: [
            ],
            bgStyle: {
                'background': `#fff url(${require('@/assets/images/order/indexBg.png')}) no-repeat top center/100vw 63.33vw`
            }
        }
    },
    created() {
        this.getRechargeProduct();
    },
    methods: {
        test() {
            this.show = !this.show;
        },
        getRechargeProduct() {
            this.loading = true;
            getRechargeProduct().then((res) => {
                if (!isEmpty(res.categoryList)) {
                    this.sideList = res.categoryList.map((item) => {
                        return {
                            ...item,
                            id: item.categoryId
                        }
                    })
                }
                this.contentList = res.contentList;
            }).finally(() => this.loading = false)
        },
        changeGoods(index) {
            this.activeGoods = index;
        },
        changeCategory(index) {
            this.activeCategory = index;
        }
    },
    components: {
        SideBar,
        TabBar
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	flex: 1;
}

.swipe {
    padding: 5px 10px;
}
</style>
