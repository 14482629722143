<template>
    <van-tabbar v-model="active" route inactive-color="#15161a" active-color="#FF4500" style="" placeholder>
        <van-tabbar-item name="index" to="/index">
            <span class="route-title">电影</span>
            <template #icon="props">
                <img :src="props.active ? activeImage[0] : inactiveImage[0]" style="width: 28px;height: 28px;">
            </template>
        </van-tabbar-item>
        <van-tabbar-item name="store" to="/store">
            <span class="route-title">音乐视频</span>
            <template #icon="props">
                <img :src="props.active ? activeImage[1] : inactiveImage[1]" style="width: 28px;height: 28px;">
            </template></van-tabbar-item>
        <van-tabbar-item name="user" to="/user">
            <span class="route-title">个人中心</span>
            <template #icon="props">
                <img :src="props.active ? activeImage[2] : inactiveImage[2]" style="width: 28px;height: 28px;">
            </template></van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            inactiveImage: [
                require('@/components/tab-bar/icons/movie-cinema.png'),
                require('@/components/tab-bar/icons/recharge.png'),
                require('@/components/tab-bar/icons/user.png')
            ],
            activeImage: [
                require('@/components/tab-bar/icons/movie-cinema_active.png'),
                require('@/components/tab-bar/icons/recharge_active.png'),
                require('@/components/tab-bar/icons/user_active.png')
            ]
        }
    },
    watch: {
        active() {
            console.log(this.active)
        }
    }
}
</script>

<style lang="scss" scoped>
.route-title {
	font-size: 12px;
    color: $second-color;
}

/deep/ .van-tabbar {
	padding: 5px 0 10px;
}
</style>
