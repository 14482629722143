<template>
    <div class="side-bar">
        <div class="side-box--wrap">
            <div class="side-nav" @scroll="handleScrollLeft">
                <div class="nav-item" :class="item.id == activeIndex ? 'active' : ''" v-for="item of list" :key="item.id" @click="changeNav(item.id)" :data-active="item.id == activeIndex" ref="navItems">
                    <div class="nav-content">
                        <div class="nav-title">{{item.name}}</div>
                        <div class="nav-title--desc">{{item.subName}}</div>
                    </div>
                </div>
            </div>
            <div class="side-content" @scroll="handleScrollRight" ref="sideContent">
                <div class="content-item" v-for="item, index in contentList" :key="index" ref="contentItems" :data-parent="item.categoryId">
                    <div v-if="item.name" class="content-title">{{item.name}}</div>
                    <div class="content-main">
                        <div class="fx-column fx-center product-item" v-for="t, i in item.subCategoryList" :key="i" @click="goPay(t)">
                            <img class="product-img" :src="t.logo" />
                            <div class="name">{{t.name}}</div>
                            <div class="desc">{{t.subName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { findIndex, filter } from 'lodash'
export default {
    name: 'side-bar',
    props: {
        list: {
            type: Array,
            default: () => []
        },
        contentList: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            activeIndex: 16,
        }
    },
    methods: {
        goPay(i) {
            this.$goPage('store-sku', {
                pid: i.subCategoryId
            })
        },
        changeNav(id) {
            this.activeIndex = id;
            let contentItems = this.$refs.contentItems;
            let item = filter(contentItems, (item) => {
                return item.dataset.parent == id
            })[0];

            this.$refs.sideContent.scrollTo({
                top: item.offsetTop,
                behavior: 'smooth'
            })

        },
        handleScrollLeft(e) {
        },
        handleScrollRight() {
        }
    }
}
</script>

<style lang="scss" scoped>
.side-bar {
	height: 100%;
	position: relative;
	.side-box--wrap {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: row;
	}

	.side-content {
		padding: 0 8px 8px;
		height: 100%;
		overflow-y: scroll;
		flex: 1;
		background-color: #fff;
		.content-item {
			padding: 0 10px;
			.content-title {
				background: url("./privilegeBg.png") no-repeat center/63.2vw 4vw;
				padding: 2px 0;
				text-align: center;
				font: bold 3.2vw/4vw simhei;
				color: #15161a;
			}

			.content-main {
				display: grid;
				grid-template-columns: repeat(3, auto);
				justify-content: space-between;
				padding: 0px 0 20px;
				.product-item {
					margin-top: 10px;
				}
				.product-img {
					width: 50px;
					height: 50px;
				}

				.name,
				.desc {
					width: 20vw;
					text-align: center;
					@include txt-ellipsis;
				}

				.name {
					color: #15161a;
				}

				.desc {
					color: $tips-color;
				}
			}
		}
	}

	.side-nav {
		height: 100%;
		background-color: $bg-color;
		overflow-y: scroll;
		@include scroll-bar-none;
		.nav-item {
			position: relative;
			padding: 12px 6px;
			&.active {
				background-color: #fff;
				&::after {
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					width: 1vw;
					height: 50%;
					background: #fe1a29;
				}
				.nav-title--desc {
					color: #fe1a29 !important;
					background-color: rgba(254, 26, 41, 0.2) !important;
				}
			}

			.nav-content {
				display: flex;
				flex-direction: column;
				align-items: center;
				.nav-title {
					font-size: 13px;
					font-weight: 700;
					color: #262626;
					padding: 0 15px;
				}

				.nav-title--desc {
					margin-top: 4px;
					background-color: #cdcdcd;
					padding: 0px 8px;
					border-radius: 999px;
					color: #f5f5f5;
				}
			}
		}
	}
}
</style>
